import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import mainStyles from "../styles/css/main.module.scss"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className={mainStyles.majorSection}>
        <div className={mainStyles.mainPadding}>
          <h1>Page Not Found</h1>
        </div>
    </section>
  </Layout>
)

export default NotFoundPage
